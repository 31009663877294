import React, { Component } from 'react';
import moment from 'moment';
import momentDurationFormatSetup from 'moment-duration-format';

momentDurationFormatSetup(moment);

class LeaderBoard extends Component {
  constructor(props) {
    super(props);
    this.state = { leaders: props.leaders };
  }


  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.mode === 'count') {
      let uniqueLeaders = {};
        for (var i = 0; i < nextProps.leaders.length; i++) {
          let leader = nextProps.leaders[i];
          let lcLeaderName = leader.name.toLowerCase();
          let record = uniqueLeaders[lcLeaderName];
          if (record) {
            record.count++;
            if (leader.timestamp > record.timestamp) {
              record.timestamp = leader.timestamp;
            }
          } else {
            uniqueLeaders[lcLeaderName] = {
              name: leader.name,
              timestamp: leader.timestamp,
              count: 1
            }
          }
        }
        const leadersByCount =
          Object.values(uniqueLeaders).sort((a, b) => {
            return b.count - a.count;
          });

        return {leaders: leadersByCount};
    }

    return {leaders: nextProps.leaders};
  }

  renderEmptyState() {
    if (!this.props.leaders || !this.props.leaders.length) {
      return (
        <div>
          <p>There's no one on the leaderboard yet!</p>
          <p>Get {this.props.size} phrases in a row to add yourself to the list.</p>
        </div>
      );
    }
    return null;
  }

  renderTrophies(count) {
    let trophies = '';
    for (var i = 0; i < count; i++) {
      trophies += '🏆 ';
    }
    let title = count + ' Bingo' + (count !== 1 ? 's!' : '!');
    return (
      <span aria-label={title} title={title}>
        <span aria-hidden="true">
          {trophies}
        </span>
      </span>
    );
  }

  renderByCount() {
    return (
      <aside className='maxw-95' aria-label="Leaderboard">
        <h2 className="bb-3 pv2">Leaderboard</h2>
        <div role='log' aria-live='polite' aria-atomic='true'>
          <ul className="f4 list pa0">
            {this.state.leaders.map((leader, i) => {
              return (
                <li key={i} className='ph1 pb3'>
                  {this.renderTrophies(leader.count)}
                  <strong>
                    {leader.name}
                    {' '}&middot;{' '}
                  </strong>
                  {moment(leader.timestamp).format('MMM D, h:mm a')}
                </li>
              );
            })}
          </ul>
          {this.renderEmptyState()}
        </div>
      </aside>
    );
  }

  renderBySpeed() {
    return (
      <aside className='maxw-95' aria-label="Leaderboard">
        <h2 className="bb-3 pv2">Leaderboard</h2>
        <div role='log' aria-live='polite' aria-atomic='true'>
          <ol className="f4 list pa0">
            {this.state.leaders.map((leader, i) => {
              return (
                <li key={i} className='ph1 pb3'>
                  <span aria-hidden="true">🏆 </span> 
                  <strong>
                    {leader.name}
                    {' '}&middot;{' '}
                    {moment.duration(leader.duration).format('h [hr], m [min], s [sec]')}
                    {' '}&middot;{' '}
                  </strong>
                  {moment(leader.timestamp).format('L LT')}
                </li>
              );
            })}
          </ol>
          {this.renderEmptyState()}
        </div>
      </aside>
    );
  }

  render() {
    return (this.props.mode === 'count') ? this.renderByCount() : this.renderBySpeed();
  }
}

export default LeaderBoard;